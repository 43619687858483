/* Core */
import Vue from 'vue'
// import { IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap and its CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js' // Import Bootstrap JS (required for accordion and other components)

import '@mdi/font/css/materialdesignicons.css'
import Buefy from 'buefy'
import VueCompositionAPI from '@vue/composition-api'
/* Router & Store */
import router from './router'
import store from './store'
import * as moment from 'moment/moment'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import veeValidateConfigure from '@/plugins/vee-validate'

// Import the Auth0 configuration
import { domain, clientId } from '../auth_config.json'

// Import the plugin here
import { Auth0Plugin } from './auth'

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
veeValidateConfigure()

/* Composition API */
Vue.use(VueCompositionAPI)

/* Fetch sample data */
store.dispatch('fetch', 'updates')

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})

Vue.filter('formatDateMonth', function (value) {
  if (value) {
    moment.locale('ro')
    return moment(String(value)).format('MMMM-YYYY')
  }
})

Vue.filter('formatDateRoDetail', function (value) {
  if (value) {
    moment.locale('ro')
    return moment(String(value)).format('dddd, Do MMMM, YYYY')
  }
})

Vue.component('ValidationProvider', ValidationProvider)
/*
* [Production notice]
* Please check Vue.render() samples starting at line 51 here.
* It's likely you may safely remove one of these imports in production:
* */
const AppDefault = () => import('./AppDefault.vue')
const AppLightDark = () => import('./AppLightDark.vue')

/* Default title tag */
const defaultDocumentTitle = 'Portal Linguapro'

/* Detect mobile layout */
store.dispatch('layoutMobileToggle')

window.onresize = () => {
  store.dispatch('layoutMobileToggle')
}

/* Collapse mobile aside menu on route change & set title */
router.afterEach(to => {
  store.commit('asideMobileStateToggle', false)
  store.commit('overlayToggle', false)
  store.commit('asideActiveForcedKeyToggle', null)

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
})

Vue.config.productionTip = false

// Make BootstrapVue available throughout your project
// Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)

Vue.use(Buefy)

new Vue({
  router,
  store,

  // Default style example
  // render (h) {
  //   return h(AppDefault)
  // },

  // Light mode example
  // render (h) {
  //   store.commit('appStyle', 'light-dark') // ... or change default in src/store/index.js
  //   return h(AppLightDark)
  // },

  // Dark mode example
  // render (h) {
  //   store.commit('appStyle', 'light-dark') // ... or change default in src/store/index.js
  //   store.commit('darkModeToggle', true) // ... or change default in src/store/index.js
  //   return h(AppLightDark)
  // },

  // Demo render() with default & light-dark styles
  render (h) {
    const queryString = window.location.search

    // Demo only. Iframe preview mode
    if (queryString.indexOf('iframe-preview-mode') > -1) {
      store.commit('iframePreviewMode', true)
    }

    // Demo only. Load proper style
    const appStyle = queryString.indexOf('style=light-dark') < 0 ? 'default' : 'light-dark'

    store.commit('appStyle', appStyle)

    if (appStyle === 'light-dark' && queryString.indexOf('style=light-dark-dark') > -1) {
      store.commit('darkModeToggle', true)
    }

    return h(appStyle === 'default' ? AppDefault : AppLightDark)
  }
}).$mount('#app')
