<template>
  <div>
    <b-loading
      :is-full-page="true"
      :active="isLoading"
    />
    <article
      v-for="(client, clientIndex) in data.customers"
      :key="clientIndex"
      class="box"
    >
      <b-field :message="`Număr de module afișate: ${client.modules.length}`">
        <p class="control">
          <b-dropdown aria-role="list">
            <template #trigger="{ active }">
              <b-button
                :label="`${client.name}`"
                type="is-primary"
                :icon-right="active ? 'menu-up' : 'menu-down'"
              />
            </template>
            <b-dropdown-item
              aria-role="listitem"
              @click="downloadCustomerReport(client.id, client.name)"
            >
              {{ `Descarcă raport factură (${getRomanianMonthName()}) ` }}
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="navigatetoReports()"
            >
              Arhiva rapoarte factura...
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="downloadMonthlyReport(client.id, client.name)"
            >
              Descarca raport lunar
            </b-dropdown-item>
          </b-dropdown>
        </p>
      </b-field>
      <b-menu>
        <b-menu-list>
          <b-menu-item
            v-for="(mod, modIndex) in client.modules"
            :key="modIndex"
            tag="a"
          >
            <template #label="props">
              <p>
                {{ mod.name }}, {{ mod.startDate | formatDate }} - {{ mod.endDate | formatDate }}, {{ mod.numberOfHours }} ore
              </p>
              <b-icon
                class="is-pulled-right"
                :icon="props.expanded ? 'menu-up' : 'menu-down'"
              />
            </template>
            <b-tabs
              v-model="activeTab"
              position="is-centered"
              type="is-toggle-rounded"
            >
              <b-tab-item
                v-for="(tab, tabIndex) in tabs"
                :key="tabIndex"
                :label="getTabLabel(tab, mod.groupCounts, tabIndex)"
              >
                <div
                  v-for="(group, groupIndex) in filteredGroupsByTab(mod.groups)[tabIndex]"
                  :key="groupIndex"
                >
                  <div class="panel-block is-block">
                    <router-link
                      :to="{ name: 'groupattendance', params: { id: group.id } }"
                    >
                      <b-menu-item
                        :label="`${group.name} | ${group.level || 'N/A'}, ${group.type || 'N/A'}, ${group.teacher || 'N/A'}`"
                        icon="link"
                      />
                    </router-link>
                  </div>
                </div>
              </b-tab-item>
            </b-tabs>
          </b-menu-item>
        </b-menu-list>
      </b-menu>
    </article>
  </div>
</template>

<script>
import { computed, ref, watchEffect } from '@vue/composition-api'
import ClientService from '@/services/clients.service'
import * as moment from 'moment/moment'
import 'moment/locale/ro'

export default {
  name: 'GeneralOverviewDashboard',
  props: {
    data: {
      type: Object,
      required: true,
      default: null
    }
  },
  setup (props, { root: { $buefy } }) {
    const isLoading = ref(false)
    const activeTab = ref(0)

    const tabs = ref([
      { label: 'Active', count: 0 },
      { label: 'Finalizate', count: 0 },
      { label: 'Toate', count: 0 }
    ])

    watchEffect(() => {
      if (props.data && Array.isArray(props.data.customers)) {
        props.data.customers.forEach(client => {
          if (Array.isArray(client.modules)) {
            client.modules.forEach(module => {
              if (Array.isArray(module.groups)) {
                const groupCounts = filteredGroupsByTab.value(module.groups).map(groups => groups.length)
                module.groupCounts = groupCounts
              }
            })
          }
        })
      }
    })

    const filteredGroupsByTab = computed(() => (groups = []) => {
      const filters = [
        (group) => !group.isCompleted,
        (group) => group.isCompleted,
        null
      ]

      return filters.map(filterFn => {
        if (filterFn) {
          return groups.filter(group => filterFn(group))
        }
        return groups
      })
    })

    const getRomanianMonthName = () => {
      moment.locale('ro')
      const currentDate = moment()
      return currentDate.format('MMMM')
    }

    return {
      isLoading,
      getRomanianMonthName,
      activeTab,
      tabs,
      filteredGroupsByTab
    }
  },
  methods: {
    getTabLabel (tab, groupCounts, index) {
      return `${tab.label.split(' ')[0]} (${groupCounts && groupCounts[index] ? groupCounts[index] : 0})`
    },
    async downloadCustomerReport (customerId, customerName) {
      this.isLoading = true
      const { getCustomerInvoiceReport } = ClientService
      await getCustomerInvoiceReport(customerId, customerName, moment())
      this.isLoading = false
    },
    navigatetoReports () {
      this.$router.push({ path: '/reports' })
    },
    async downloadMonthlyReport (customerId, customerName) {
      this.isLoading = true
      const { getMonthlyReport } = ClientService
      await getMonthlyReport(customerId, customerName)
      this.isLoading = false
    }
  }
}
</script>
