import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import { authenticationGuard } from '@/auth/authenticationGuard'

Vue.use(VueRouter)

const routes = [
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Centralizator cursuri Linguapro'
    },
    path: '/',
    name: 'home',
    component: Home
  },
  {
    meta: {
      title: 'Rapoarte'
    },
    path: '/reports/:customerId',
    name: 'reports.customerId',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "tables" */ '@/views/ClientReports.vue'),
    props: true
  },
  {
    meta: {
      title: 'Rapoarte'
    },
    path: '/reports',
    name: 'rapoarte',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "tables" */ '@/views/Reports.vue')
  },
  {
    meta: {
      title: 'Clienti'
    },
    path: '/clients',
    name: 'clients',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "tables" */ '@/views/Clients.vue')
  },
  {
    meta: {
      title: 'Module'
    },
    path: '/modules',
    name: 'modules',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "tables" */ '@/views/Modules.vue')
  },
  {
    meta: {
      title: 'Grupe'
    },
    path: '/groups',
    name: 'groups',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "tables" */ '@/views/Groups.vue')
  },
  {
    meta: {
      title: 'Profesori'
    },
    path: '/teachers',
    name: 'teachers',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "tables" */ '@/views/Teachers.vue')
  },
  {
    meta: {
      title: 'Profesor nou'
    },
    path: '/teacher/new',
    name: 'teacher.new',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/TeacherForm.vue')
  },
  {
    meta: {
      title: 'Editare profesor'
    },
    path: '/teacher/:id',
    name: 'teacher.edit',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/TeacherForm.vue'),
    props: true
  },
  {
    meta: {
      title: 'Cursanti'
    },
    path: '/students',
    name: 'students',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "tables" */ '@/views/Students.vue')
  },
  {
    meta: {
      title: 'Client nou'
    },
    path: '/client/new',
    name: 'client.new',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/ClientForm.vue')
  },
  {
    meta: {
      title: 'Editare client'
    },
    path: '/client/:id',
    name: 'client.edit',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/ClientForm.vue'),
    props: true
  },
  {
    meta: {
      title: 'Prezenta noua'
    },
    path: '/attendance/:studentId/new',
    name: 'attendance.new',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/AttendanceForm.vue'),
    props: true
  },
  {
    meta: {
      title: 'Editare prezenta'
    },
    path: '/attendance/:id',
    name: 'attendance.edit',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/AttendanceForm.vue'),
    props: true
  },
  {
    meta: {
      title: 'Modul nou'
    },
    path: '/module/new',
    name: 'module.new',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/ModuleForm.vue')
  },
  {
    meta: {
      title: 'Modul nou'
    },
    path: '/module/:clientId/new',
    name: 'module.newClientId',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/ModuleForm.vue'),
    props: true
  },
  {
    meta: {
      title: 'Editare modul'
    },
    path: '/module/:id',
    name: 'module.edit',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/ModuleForm.vue'),
    props: true
  },
  {
    meta: {
      title: 'Grupa noua'
    },
    path: '/group/new',
    name: 'group.new',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/GroupForm.vue')
  },
  {
    meta: {
      title: 'Groupa noua'
    },
    path: '/group/:moduleId/new',
    name: 'group.newModuleId',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/GroupForm.vue'),
    props: true
  },
  {
    meta: {
      title: 'Prezente grupa'
    },
    path: '/groupattendance/:id',
    name: 'groupattendance',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/GroupAttendanceForm.vue'),
    props: true
  },
  {
    meta: {
      title: 'Editare grupa'
    },
    path: '/group/:id',
    name: 'group.edit',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/GroupForm.vue'),
    props: true
  },
  {
    meta: {
      title: 'Sedinta noua'
    },
    path: '/lesson/:groupId/new',
    name: 'lesson.new',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/LessonForm.vue'),
    props: true
  },
  {
    meta: {
      title: 'Editare sedinta'
    },
    path: '/lesson/:id',
    name: 'lesson.edit',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/LessonForm.vue'),
    props: true
  },
  {
    meta: {
      title: 'Cursant nou'
    },
    path: '/student/:groupId/new',
    name: 'student.newGroupId',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/StudentForm.vue'),
    props: true
  },
  {
    meta: {
      title: 'Cursant nou'
    },
    path: '/student/new',
    name: 'student.new',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/StudentForm.vue')
  },
  {
    meta: {
      title: 'Editare cursant'
    },
    path: '/student/:id',
    name: 'student.edit',
    beforeEnter: authenticationGuard,
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/StudentForm.vue'),
    props: true
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

// Fix for NavigationDuplicated error
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err
    }
  })
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err
    }
  })
}

export default router

export function useRouter () {
  return router
}
