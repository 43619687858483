<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
      <router-link
        slot="right"
        :to="heroRouterLinkTo"
        class="button is-primary"
      >
        {{ heroRouterLinkLabel }}
      </router-link>
    </hero-bar>
    <b-loading
      :is-full-page="true"
      :active="isLoading"
    />
    <section class="section is-main-section">
      <tiles>
        <card-component
          :title="formCardTitle"
          icon="account-group"
          class="tile is-child"
        >
          <ValidationObserver ref="form">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Nume"
                    rules="required"
                  >
                    <b-field
                      id="name"
                      name="name"
                      :type="errors[0] ? 'is-danger' : ''"
                      label="Nume"
                      :message="errors[0] ? errors[0] : 'Nume grupa'"
                    >
                      <b-input
                        v-model="group.name"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Modul"
                    rules="required"
                  >
                    <b-field
                      id="module"
                      name="module"
                      :type="errors[0] ? 'is-danger' : ''"
                      label="Modul"
                      :message="errors[0] ? errors[0] : 'Modulul pentru care va fi creata grupa'"
                    >
                      <b-select
                        v-model="group.moduleId"
                        placeholder="Selectati un modul"
                        expanded
                      >
                        <option
                          v-for="(mod, index) in moduleList"
                          :key="index"
                          :value="mod.id"
                        >
                          {{ mod.name }} ({{ mod.startDate | formatDate }} - {{ mod.endDate | formatDate }})
                        </option>
                      </b-select>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Nivel"
                    rules="required"
                  >
                    <b-field
                      id="level"
                      name="level"
                      :type="errors[0] ? 'is-danger' : ''"
                      :message="errors[0] ? errors[0] : 'Nivelul grupei'"
                      label="Nivel"
                    >
                      <b-select
                        v-model="group.levelId"
                        placeholder="Selectati un nivel"
                        expanded
                      >
                        <option
                          v-for="(groupLevel, index) in groupLevels"
                          :key="index"
                          :value="groupLevel.id"
                        >
                          {{ groupLevel.name }}
                        </option>
                      </b-select>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Profesor"
                    rules="required"
                  >
                    <b-field
                      id="teacher"
                      name="teacher"
                      :type="errors[0] ? 'is-danger' : ''"
                      :message="errors[0] ? errors[0] : 'Profesorul atribuit grupei'"
                      label="Profesor"
                      expanded
                    >
                      <b-select
                        v-model="group.teacherId"
                        placeholder="Selectati un profesor"
                        expanded
                      >
                        <option
                          v-for="(teacher, index) in teacherList"
                          :key="index"
                          :value="teacher.id"
                        >
                          {{ teacher.firstName }} {{ teacher.lastName }}
                        </option>
                      </b-select>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Tip"
                    rules="required"
                  >
                    <b-field
                      id="type"
                      name="type"
                      :type="errors[0] ? 'is-danger' : ''"
                      :message="errors[0] ? errors[0] : 'Tip grupa'"
                      label="Tip"
                      expanded
                    >
                      <b-select
                        v-model="group.typeId"
                        placeholder="Selectati un tip de grupa"
                        expanded
                      >
                        <option
                          v-for="(groupType, index) in groupTypesList"
                          :key="index"
                          :value="groupType.id"
                        >
                          {{ groupType.name }}
                        </option>
                      </b-select>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Durata"
                    rules="required|numeric"
                  >
                    <b-field
                      id="lessonDuration"
                      name="lessonDuration"
                      :type="errors[0] ? 'is-danger' : ''"
                      :message="errors[0] ? errors[0] : 'Durata implicita a unei sedinte (ore)'"
                      label="Durata sedinta"
                      expanded
                    >
                      <b-input
                        v-model="group.lessonDuration"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="column">
                <b-field
                  label="Manual"
                  expanded
                >
                  <b-input
                    v-model="group.textBook"
                  />
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Cost client"
                    rules="required|numeric"
                  >
                    <b-field
                      id="costPerClient"
                      name="costPerClient"
                      :type="errors[0] ? 'is-danger' : ''"
                      :message="errors[0] ? errors[0] : 'Cost client/ora (RON)'"
                      label="Cost client"
                      expanded
                    >
                      <b-input
                        v-model="group.costPerClient"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Cost profesor"
                    rules="required|numeric"
                  >
                    <b-field
                      id="costPerTeacher"
                      name="costPerTeacher"
                      :type="errors[0] ? 'is-danger' : ''"
                      :message="errors[0] ? errors[0] : 'Cost profesor/ora (RON)'"
                      label="Cost profesor"
                      expanded
                    >
                      <b-input
                        v-model="group.costPerTeacher"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Stare grupa"
                    rules="required"
                  >
                    <b-field
                      id="isCompleted"
                      name="isCompleted"
                      :type="errors[0] ? 'is-danger' : ''"
                      :label="`Stare grupa: ${group.isCompleted ? 'Finalizată' : 'În progres'}`"
                      expanded
                    >
                      <b-checkbox
                        v-model="group.isCompleted"
                        :true-value="true"
                        :false-value="false"
                      >
                        Selectați această opțiune pentru a schimbare starea grupei.
                      </b-checkbox>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </ValidationObserver>
          <card-component
            title="Orar"
            icon="calendar"
          >
            <div class="columns">
              <div class="column">
                <div class="columns is-flex">
                  <div class="column">
                    <label><label><strong>Luni, de la:</strong></label></label>
                    <vue-timepicker
                      v-model="group.schedule.mondayStart"
                      placeholder="Ora de inceput"
                      hide-disabled-hours
                      hide-disabled-minutes
                      close-on-complete
                      :hour-range="[6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]"
                      :minute-range="[0, 15, 30, 45]"
                      input-width="100%"
                    />
                  </div>
                  <div class="column">
                    <span><strong>Luni, pana la:</strong> </span>
                    <vue-timepicker
                      v-model="group.schedule.mondayEnd"
                      hide-disabled-hours
                      hide-disabled-minutes
                      close-on-complete
                      :hour-range="[6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]"
                      :minute-range="[0, 15, 30, 45]"
                      placeholder="Ora de final"
                      input-width="100%"
                    />
                  </div>
                </div>
                <div class="columns is-flex">
                  <div class="column">
                    <label><strong>Marti, de la: </strong> </label>
                    <vue-timepicker
                      v-model="group.schedule.tuesdayStart"
                      placeholder="Ora de inceput"
                      hide-disabled-hours
                      hide-disabled-minutes
                      close-on-complete
                      :hour-range="[6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]"
                      :minute-range="[0, 15, 30, 45]"
                      input-width="100%"
                    />
                  </div>
                  <div class="column">
                    <span><strong>Marti, pana la: </strong></span>
                    <vue-timepicker
                      v-model="group.schedule.tuesdayEnd"
                      hide-disabled-hours
                      hide-disabled-minutes
                      close-on-complete
                      :hour-range="[6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]"
                      :minute-range="[0, 15, 30, 45]"
                      placeholder="Ora de final"
                      input-width="100%"
                    />
                  </div>
                </div>
                <div class="columns is-flex">
                  <div class="column">
                    <label><strong>Miercuri, de la: </strong></label>
                    <vue-timepicker
                      v-model="group.schedule.wednesdayStart"
                      placeholder="Ora de inceput"
                      hide-disabled-hours
                      hide-disabled-minutes
                      close-on-complete
                      :hour-range="[6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]"
                      :minute-range="[0, 15, 30, 45]"
                      input-width="100%"
                    />
                  </div>
                  <div class="column">
                    <span><strong>Miercuri, pana la: </strong></span>
                    <vue-timepicker
                      v-model="group.schedule.wednesdayEnd"
                      hide-disabled-hours
                      hide-disabled-minutes
                      :hour-range="[6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]"
                      :minute-range="[0, 15, 30, 45]"
                      placeholder="Ora de final"
                      input-width="100%"
                    />
                  </div>
                </div>
                <div class="columns is-flex">
                  <div class="column">
                    <label><strong>Joi, de la: </strong></label>
                    <vue-timepicker
                      v-model="group.schedule.thursdayStart"
                      placeholder="Ora de inceput"
                      hide-disabled-hours
                      hide-disabled-minutes
                      close-on-complete
                      :hour-range="[6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]"
                      :minute-range="[0, 15, 30, 45]"
                      input-width="100%"
                    />
                  </div>
                  <div class="column">
                    <span><strong>Joi, pana la: </strong> </span>
                    <vue-timepicker
                      v-model="group.schedule.thursdayEnd"
                      hide-disabled-hours
                      hide-disabled-minutes
                      close-on-complete
                      :hour-range="[6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]"
                      :minute-range="[0, 15, 30, 45]"
                      placeholder="Ora de final"
                      input-width="100%"
                    />
                  </div>
                </div>
                <div class="columns is-flex">
                  <div class="column">
                    <label><strong>Vineri, de la:</strong> </label>
                    <vue-timepicker
                      v-model="group.schedule.fridayStart"
                      placeholder="Ora de inceput"
                      hide-disabled-hours
                      hide-disabled-minutes
                      close-on-complete
                      :hour-range="[6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]"
                      :minute-range="[0, 15, 30, 45]"
                      input-width="100%"
                    />
                  </div>
                  <div class="column">
                    <span><strong>Vineri, pana la:</strong> </span>
                    <vue-timepicker
                      v-model="group.schedule.fridayEnd"
                      hide-disabled-hours
                      hide-disabled-minutes
                      close-on-complete
                      :hour-range="[6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]"
                      :minute-range="[0, 15, 30, 45]"
                      placeholder="Ora de final"
                      input-width="100%"
                    />
                  </div>
                </div>
                <div class="columns is-flex">
                  <div class="column">
                    <label><strong>Sambata, de la: </strong></label>
                    <vue-timepicker
                      v-model="group.schedule.saturdayStart"
                      placeholder="Ora de inceput"
                      hide-disabled-hours
                      hide-disabled-minutes
                      close-on-complete
                      :hour-range="[6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]"
                      :minute-range="[0, 15, 30, 45]"
                      input-width="100%"
                    />
                  </div>
                  <div class="column">
                    <span><strong>Sambata, pana la:</strong></span>
                    <vue-timepicker
                      v-model="group.schedule.saturdayEnd"
                      hide-disabled-hours
                      hide-disabled-minutes
                      close-on-complete
                      :hour-range="[6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]"
                      :minute-range="[0, 15, 30, 45]"
                      placeholder="Ora de final"
                      input-width="100%"
                    />
                  </div>
                </div>
                <div class="columns is-flex">
                  <div class="column">
                    <label><strong>Duminca, de la:</strong> </label>
                    <vue-timepicker
                      v-model="group.schedule.sundayStart"
                      placeholder="Ora de inceput"
                      hide-disabled-hours
                      hide-disabled-minutes
                      close-on-complete
                      :hour-range="[6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]"
                      :minute-range="[0, 15, 30, 45]"
                      input-width="100%"
                    />
                  </div>
                  <div class="column">
                    <span><strong>Duminica, pana la: </strong></span>
                    <vue-timepicker
                      v-model="group.schedule.sundayEnd"
                      hide-disabled-hours
                      hide-disabled-minutes
                      close-on-complete
                      :hour-range="[6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]"
                      :minute-range="[0, 15, 30, 45]"
                      placeholder="Ora de final"
                      input-width="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </card-component>

          <b-field grouped>
            <div class="control">
              <b-button
                type="is-primary"
                native-type="submit"
                @click="save()"
              >
                Salvare
              </b-button>
            </div>
            <div class="control">
              <b-button
                type="is-danger is-outlined"
                @click="cancel()"
              >
                Anulare
              </b-button>
            </div>
          </b-field>
        </card-component>
        <card-component
          v-if="ifGroupExists"
          title="Informatii grupa"
          icon="account-group"
          class="tile is-child"
        >
          <b-tabs>
            <b-tab-item
              label="Calendar lectii"
              icon="calendar-text"
            >
              <button
                class="button is-primary"
                @click="addLesson()"
              >
                Sedinta noua
              </button>
              <lessons-table-sample
                :lessons="groupLessons"
                @handleDeleteLesson="handleDeleteLesson"
              />
            </b-tab-item>

            <b-tab-item
              label="Cursanti"
              icon="account-school"
            >
              <button
                class="button is-primary"
                @click="addStudent()"
              >
                Cursant nou
              </button>
              <students-table-sample
                :students="groupStudents"
                @handleDeleteStudent="handleDeleteStudent"
              />
            </b-tab-item>
            <b-tab-item
              label="Prezente"
              icon="chart-box"
            >
              <router-link
                class="button is-primary"
                :to="{name:'groupattendance', params: {id: group.id}}"
              >
                Prezente
              </router-link>
            </b-tab-item>
          </b-tabs>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import { useRouter } from '@/router'
import { reactive, computed, ref, watch } from '@vue/composition-api'
import TitleBar from '@/components/TitleBar.vue'
import HeroBar from '@/components/HeroBar.vue'
import Tiles from '@/components/Tiles.vue'
import CardComponent from '@/components/CardComponent.vue'
import StudentsTableSample from '@/components/StudentsTableSample.vue'
import LessonsTableSample from '@/components/LessonsTableSample.vue'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import ModuleService from '@/services/modules.service'
import GroupService from '@/services/groups.service'
import TeacherService from '@/services/teachers.service'
import LessonService from '@/services/lessons.service'
import StudentService from '@/services/students.service'

export default {
  name: 'GroupForm',
  components: {
    CardComponent,
    Tiles,
    HeroBar,
    TitleBar,
    StudentsTableSample,
    LessonsTableSample,
    VueTimepicker
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    },
    moduleId: {
      type: [String, Number],
      default: null
    }
  },
  setup (props, { root: { $buefy } }) {
    const ifGroupExists = ref(false)
    const moduleList = ref([])
    const teacherList = ref([])
    const groupStudents = ref([])
    const groupLessons = ref([])
    const groupLevels = ref([])
    const groupTypesList = ref([])

    const titleStack = computed(() => ['Linguapro', 'Grupe', ifGroupExists.value ? group.name : 'Adaugare grupa'])

    const group = reactive({
      id: null,
      name: null,
      moduleId: null,
      typeId: null,
      levelId: null,
      teacherId: null,
      costPerClient: null,
      costPerTeacher: null,
      lessonDuration: null,
      isCompleted: false,
      schedule: {
        mondayStart: null,
        mondayEnd: null,
        tuesdayStart: null,
        tuesdayEnd: null,
        wednesdayStart: null,
        wednesdayEnd: null,
        thursdayStart: null,
        thursdayEnd: null,
        fridayStart: null,
        fridayEnd: null,
        saturdayStart: null,
        saturadayEnd: null,
        sundayStart: null,
        sundayEnd: null
      }
    })

    const groupModule = reactive({
      id: null,
      name: null
    })

    async function getModules () {
      try {
        const { getModules } = ModuleService
        await getModules().then((response) => {
          moduleList.value = response.data
        })
      } catch (err) {
      }
    }

    getModules()

    async function getTeachers () {
      try {
        const { getTeachers } = TeacherService
        await getTeachers().then((response) => {
          teacherList.value = response.data
        })
      } catch (err) {
      }
    }

    getTeachers()

    async function getGroupLevels () {
      try {
        const { getGroupLevels } = GroupService
        await getGroupLevels().then((response) => {
          groupLevels.value = response.data
        })
      } catch (err) {
      }
    }

    getGroupLevels()

    async function getGroupTypes () {
      try {
        const { getGroupTypes } = GroupService
        await getGroupTypes().then((response) => {
          groupTypesList.value = response.data
        })
      } catch (err) {
      }
    }

    getGroupTypes()

    const heroTitle = computed(() => ifGroupExists.value ? 'Editare grupa' : groupModule.name ? `Grupa noua pentru modulul ${groupModule.name}` : 'Adaugare grupa')

    const heroRouterLinkTo = computed(() => ifGroupExists.value ? { name: 'group.new' } : { name: 'home' })

    const heroRouterLinkLabel = computed(() => ifGroupExists.value ? 'Grupa noua' : 'Centralizator cursuri Linguapro')

    const formCardTitle = computed(() => ifGroupExists.value ? group.name : 'Grupa noua')

    const createdReadable = ref(null)

    const router = useRouter()

    const groupId = computed(() => props.id)

    const moduleId = computed(() => props.moduleId)

    const isLoading = ref(false)

    if (moduleId.value) {
      group.moduleId = moduleId
    }

    const getData = async () => {
      if (groupId.value) {
        try {
          const { getGroup } = GroupService
          const response = await getGroup(groupId.value)
          if (response && response.data) {
            const existingGroup = response.data
            ifGroupExists.value = true
            group.id = existingGroup.id
            group.name = existingGroup.name
            group.textBook = existingGroup.textBook
            group.moduleId = existingGroup.moduleId
            group.teacherId = existingGroup.teacherId
            group.levelId = existingGroup.levelId
            group.typeId = existingGroup.typeId
            group.costPerClient = existingGroup.costPerClient
            group.costPerTeacher = existingGroup.costPerTeacher
            group.lessonDuration = existingGroup.lessonDuration
            group.schedule = existingGroup.schedule
            group.isCompleted = existingGroup.isCompleted
          } else {
            router.push({ name: 'group.new' })
          }
        } catch (err) {
        }
      }
    }

    isLoading.value = true

    getData()

    const getModuleData = async (moduleId) => {
      const { getModule } = ModuleService
      const response = await getModule(moduleId)
      if (response && response.data) {
        const mod = response.data
        groupModule.id = mod.id
        groupModule.name = mod.name
      }
    }

    if (moduleId.value) { getModuleData(moduleId.value) }

    async function getGroupStudents () {
      try {
        const { getGroupStudents } = GroupService
        await getGroupStudents(groupId.value).then((response) => {
          groupStudents.value = response.data
        })
      } catch (err) {
      }
    }

    getGroupStudents()

    async function getGroupLessons () {
      try {
        const { getGroupLessons } = GroupService
        await getGroupLessons(groupId.value).then((response) => {
          groupLessons.value = response.data
        })
      } catch (err) {
      }
      isLoading.value = false
    }

    getGroupLessons()

    watch(groupId, newValue => {
      ifGroupExists.value = false

      if (!newValue) {
        group.id = null
        group.name = null
        group.moduleId = null
        group.levelId = null
        group.teacherId = null
        group.typeId = null
        group.textBook = null
        group.costPerClient = null
        group.costPerTeacher = null
        group.isCompleted = false
      } else {
        getData()
      }
    })

    const dateInput = v => {
      createdReadable.value = new Date(v).toLocaleDateString()
    }

    const submit = () => {
      isLoading.value = true

      setTimeout(() => {
        isLoading.value = false

        $buefy.snackbar.open({
          message: 'Salvare in curs',
          queue: false
        })
      }, 750)
    }

    return {
      ifGroupExists,
      titleStack,
      heroTitle,
      heroRouterLinkTo,
      heroRouterLinkLabel,
      formCardTitle,
      group,
      moduleList,
      groupLevels,
      groupTypesList,
      teacherList,
      groupStudents,
      groupLessons,
      createdReadable,
      dateInput,
      isLoading,
      submit,
      selected: {},
      groupId
    }
  },
  methods: {
    async handleDeleteStudent (id) {
      const { deleteStudent } = StudentService
      const response = await deleteStudent(id)
      const { status } = response
      if (status === 200) {
        const index = this.students.findIndex((prop) => prop.id === id)
        this.students.splice(index, 1)
        this.$buefy.snackbar.open({
          message: 'Inregistrea a fost stearsa.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la stergerea inregistrarii.',
          queue: false
        })
      }
    },
    save () {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return
        }
        if (this.ifGroupExists) {
          this.handleEditGroup(this.group)
        } else {
          this.handleAddGroup(this.group)
        }
      })
    },
    async handleAddGroup (newGroup) {
      const { addGroup } = GroupService
      const response = await addGroup(newGroup)
      const { status } = response
      if (status === 200) {
        this.$buefy.snackbar.open({
          message: 'Inregistrarea a fost adaugata.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la adaugarea inregistrarii',
          queue: false
        })
      }
      this.$router.back()
    },
    async handleEditGroup (group) {
      const { editGroup } = GroupService
      const response = await editGroup(group)
      const { status } = response
      if (status === 200) {
        this.$buefy.snackbar.open({
          message: 'Inregistrarea a fost editata.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la modificarea inregistrarii',
          queue: false
        })
      }
      this.$router.back()
    },
    cancel () {
      this.$router.back()
    },
    addLesson () {
      this.$router.push({ name: 'lesson.new', params: { groupId: this.groupId } })
    },
    async handleDeleteLesson (id) {
      const { deleteLesson } = LessonService
      const response = await deleteLesson(id)
      const { status } = response
      if (status === 200) {
        const index = this.groupLessons.findIndex((prop) => prop.id === id)
        this.groupLessons.splice(index, 1)
        this.$buefy.snackbar.open({
          message: 'Inregistrea a fost stearsa.',
          queue: false
        })
      } else {
        this.$buefy.snackbar.open({
          message: 'Eroare la stergerea inregistrarii.',
          queue: false
        })
      }
    },
    addStudent () {
      this.$router.push({ name: 'student.newGroupId', params: { groupId: this.groupId } })
    }
  }

}
</script>
